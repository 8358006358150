import useToggleState from "@lib/hooks/use-toggle-state"
import {
    createContext,
    useContext,
    useEffect,
    useState,
    ReactNode
} from "react"
import { Product } from "types/medusa"

interface CartDropdownContext {
    state: boolean
    open: () => void
    timedOpen: () => void
    close: () => void
    cartPopupState: boolean
    openCartPopup: () => void
    closeCartPopup: () => void
    product: string | undefined
    setProduct: (product: string | undefined) => void
    productId: string | undefined
    setProductId: (productId: string | undefined) => void
}

export const CartDropdownContext = createContext<CartDropdownContext | null>(
    null
)

export const CartDropdownProvider = ({ children }: { children: ReactNode }) => {
    const { state, close, open } = useToggleState()
    const {
        state: cartPopupState,
        close: closeCartPopup,
        open: openCartPopup
    } = useToggleState()
    const [product, setProduct] = useState<string | undefined>(undefined)
    const [productId, setProductId] = useState<string | undefined>(undefined)
    const [activeTimer, setActiveTimer] = useState<NodeJS.Timer | undefined>(
        undefined
    )

    const timedOpen = () => {
        open()
        openCartPopup()
        const timer = setTimeout(close, 5000)

        setActiveTimer(timer)
    }

    const openAndCancel = () => {
        if (activeTimer) {
            clearTimeout(activeTimer)
        }

        open()
    }

    // Clean up the timer when the component unmounts
    useEffect(() => {
        return () => {
            if (activeTimer) {
                clearTimeout(activeTimer)
            }
        }
    }, [activeTimer])

    return (
        <CartDropdownContext.Provider
            value={{
                state,
                close,
                open: openAndCancel,
                timedOpen,
                cartPopupState,
                closeCartPopup,
                openCartPopup,
                product,
                setProduct,
                productId,
                setProductId
            }}
        >
            {children}
        </CartDropdownContext.Provider>
    )
}

export const useCartDropdown = () => {
    const context = useContext(CartDropdownContext)

    if (context === null) {
        throw new Error(
            "useCartDropdown must be used within a CartDropdownProvider"
        )
    }

    return context
}
