import CookieConsent from "react-cookie-consent"

const CookieAccept = ({ onAccept }: { onAccept: Function }) => {
    return (
        <CookieConsent
            enableDeclineButton
            flipButtons
            location="bottom"
            buttonText="Ik begrijp het"
            declineButtonText="Nee, bedankt"
            cookieName="akupanel-consent-cookie"
            onAccept={() => onAccept()}
            style={{
                background: "black",
                zIndex: 99999,
                padding: "10px 77px 10px 15px",
            }}
            buttonStyle={{
                color: "#000",
                fontSize: "15px",
                background: "white",
            }}
            declineButtonStyle={{
                background: "black",
                border: "1px solid white",
                margin: "10px 10px 10px 0",
            }}
            expires={450}
        >
            Deze website maakt gebruik van cookies om uw ervaring zo goed
            mogelijk te maken.
        </CookieConsent>
    )
}
export default CookieAccept
