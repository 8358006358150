import { MEDUSA_BACKEND_URL, queryClient } from "@lib/config"
import { AccountProvider } from "@lib/context/account-context"
import { CartDropdownProvider } from "@lib/context/cart-dropdown-context"
import { MobileMenuProvider } from "@lib/context/mobile-menu-context"
import { StoreProvider } from "@lib/context/store-context"
import { CartProvider, MedusaProvider } from "medusa-react"
import { AppPropsWithLayout } from "types/global"
import { I18nProvider } from "@lib/context/intl-context"
import { GoogleAnalytics, event } from "nextjs-google-analytics"
import Script from "next/script"
import { Hydrate } from "react-query"
import "styles/globals.scss"
import "react-tooltip/dist/react-tooltip.css"
import "yet-another-react-lightbox/styles.css"
import { NextWebVitalsMetric } from "next/app"
import CookieConsent, {
    Cookies,
    getCookieConsentValue
} from "react-cookie-consent"
import CookieAccept from "@modules/layout/components/cookie-consent"
import { IS_BROWSER } from "@lib/constants"
import { useEffect, useState } from "react"

export function reportWebVitals({
    name,
    label,
    value,
    id
}: NextWebVitalsMetric) {
    //console.info({ name, label, value, id })
    event(name, {
        category:
            label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
        value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
        label: id, // id unique to current page load
        nonInteraction: true // avoids affecting bounce rate.
    })
}

function App({
    Component,
    pageProps
}: AppPropsWithLayout<{ dehydratedState?: unknown }>) {
    const getLayout = Component.getLayout ?? ((page) => page)
    const [consent, setConsent] = useState(false)

    useEffect(() => {
        if (IS_BROWSER) {
            setConsent(
                getCookieConsentValue("akupanel-consent-cookie") === "true"
            )
        }
    }, [])
    return (
        <>
            <GoogleAnalytics
                trackPageViews
                strategy="afterInteractive"
                gaMeasurementId={process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}
            />
            <Script id={"ads-tag-ga"} strategy={"afterInteractive"}>
                gtag('config', 'AW-11154218048');
            </Script>
            <MedusaProvider
                baseUrl={MEDUSA_BACKEND_URL}
                queryClientProviderProps={{
                    client: queryClient
                }}
            >
                <CookieAccept onAccept={() => setConsent(true)} />
                <I18nProvider>
                    <Hydrate state={pageProps.dehydratedState}>
                        <CartDropdownProvider>
                            <MobileMenuProvider>
                                <CartProvider>
                                    <StoreProvider>
                                        <AccountProvider>
                                            {getLayout(
                                                <Component {...pageProps} />
                                            )}
                                        </AccountProvider>
                                    </StoreProvider>
                                </CartProvider>
                            </MobileMenuProvider>
                        </CartDropdownProvider>
                        <Script
                            id={"TAWK"}
                            strategy="lazyOnload"
                            dangerouslySetInnerHTML={{
                                __html: `
						var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
						(function(){
						var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
						s1.async=true;
						s1.src='https://embed.tawk.to/63cdb22f47425128790f1171/1gndo02lf';
						s1.charset='UTF-8';
						s1.setAttribute('crossorigin','*');
						s0.parentNode.insertBefore(s1,s0);
					})();`
                            }}
                        />
                        <Script
                            id={"SHARETHIS"}
                            src="https://platform-api.sharethis.com/js/sharethis.js#property=6456ea8c3957a5001aa2215d&product=custom-share-buttons&source=platform"
                            strategy="lazyOnload"
                        />
                        <Script
                            id={"HOTJAR"}
                            strategy="lazyOnload"
                            dangerouslySetInnerHTML={{
                                __html: `
                                (function(h,o,t,j,a,r){
                                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                    h._hjSettings={hjid:3472395,hjsv:6};
                                    a=o.getElementsByTagName('head')[0];
                                    r=o.createElement('script');r.async=1;
                                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                    a.appendChild(r);
                                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
                            }}
                        />
                    </Hydrate>
                </I18nProvider>
            </MedusaProvider>
        </>
    )
}

export default App
