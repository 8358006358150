import React, {createContext, FC, useContext, useEffect, useState} from 'react'
import {IntlProvider, useIntl} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/nl'
import '@formatjs/intl-relativetimeformat/locale-data/en'

import nlMessages from '@messages/nl.json'
import enMessages from '@messages/en.json'

const locales: {[key:string]:string} = {
    nl: 'Dutch',
    en: 'English'
}

const allMessages: { [key:string]: {[key:string]: any} } = {
    nl: nlMessages,
    en: enMessages,
}

const IS_SERVER = typeof window === "undefined"
const defaultLocale = 'en'
const LOCALE_KEY = 'medusa_locale'

const IntlContext = createContext<{locale: string, setLocale: (locale: string) => void} | null>(null)

const useLocale = () => {
    const context = useContext(IntlContext)
    if (context === null) {
        throw new Error("UseLocale must be used within a I18nProvider")
    }
    return context
}
const getLocale = () => {
    if(!IS_SERVER) {
        const locale = localStorage.getItem(LOCALE_KEY)
        if (locale)
            return JSON.parse(locale).locale
    }
    return defaultLocale
}

const I18nProvider: FC = ({children}) => {
    const [locale, setLocale] = useState(getLocale())
    const [messages, setMessages] = useState(allMessages[locale] ?? allMessages[defaultLocale]);

    useEffect(() => {
        if(!IS_SERVER) {
            localStorage.setItem(
                LOCALE_KEY,
                JSON.stringify({locale})
            )
        }
    }, [locale]);


    return (
        <IntlContext.Provider value={{locale, setLocale}}>
            <IntlProvider locale={locale} messages={messages}>
                {children}
            </IntlProvider>
        </IntlContext.Provider>
    )
}

export {I18nProvider, getLocale, useLocale, locales}
